import React from "react";
// CSS helpers
import styled from "styled-components";

// Components

// Atoms
import { Title } from "components/atoms/title";
import { Button } from "components/atoms/button";

// Images
import MindMap1 from "assets/images/mindmap/mindmap-1.jpg";

import { useState } from "react";

interface HorizontalRowProps {
  mt?: number;
}

const HorizontalRow = styled.hr<HorizontalRowProps>`
  margin-right: 80px;
  margin-left: 80px;
  background: ${({ theme }) => theme.colors.gray["400"]};
  height: 1px;
  border-width: 0;
  margin-top: ${({ mt }) => mt ?? 0}px;
`;

const ContentContainer = styled.div`
  height: 320px;
  margin-right: 80px;
  margin-top: 80px;
  margin-left: 80px;
  display: flex;
`;

const TextContent = styled.div`
  margin-left: 200px;
  width: 100%;
  line-height: 200%;
`;

const CtaContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 200%;
  color: var(--text-color);
  width: 800px;
`;

const Container = styled.div``;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface IframeProps {
  isExperience?: Boolean;
}

const Iframe = styled.iframe<IframeProps>`
  opacity: ${({ isExperience }) => (isExperience === true ? 1 : 0)};
  transition: opacity 500ms ease-in-out;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const ExperienceContainer = () => {
  const [isHolder, setHolder] = useState<boolean | undefined>(false);
  const [isExperience, setExperience] = useState<boolean | undefined>(false);
  const [accessRequested, setAccessRequested] = useState<boolean | undefined>(
    false
  );

  const accessExperience = async () => {
    setAccessRequested(true);

    const launchExperience = () => {
      console.log("launch exp");

      setExperience(true);
    };

    setHolder(true);
    setTimeout(launchExperience, 4000);
  };

  return (
    <Container>
      <Title
        type="h1"
        fontSize="100"
        mobilefontsize="3O"
        mt={80}
        align="center"
      >
        PRIVATE SOCIETHY
      </Title>

      <HorizontalRow />
      <ContentContainer>
        <img src={MindMap1} alt="Societhy Experience Image" />
        <TextContent>
          We are creating those “experiential” NFTs as access to the experiences
          within the Societhy ecosystem. They will be Containerided in 5 themes
          : Fashion, Music, Sport, Art & Lifestyle. Holding a Fashion Tiny will
          provide hodler get an airdrop of tickets for all fashion events on
          Societhy.
        </TextContent>
      </ContentContainer>
      <HorizontalRow mt={80} />

      {!accessRequested && (
        <Footer>
          <CtaContainer>
            <Button type="secondary" onClick={accessExperience}>
              ACCESS EXPERIENCE
            </Button>
          </CtaContainer>
        </Footer>
      )}

      {isHolder && (
        <Footer>
          <CtaContainer>
            The Opening Ceremony is launching soon (waiting time ~3min)
          </CtaContainer>
        </Footer>
      )}
      {accessRequested && (
        <Iframe
          src="https://portal.furioos.com/embed/9YsQeYW7QFQ32SxqT?autoRun=true"
          title="The Openning Ceremony"
          frameBorder={0}
          allowFullScreen
          isExperience={isExperience}
        ></Iframe>
      )}
    </Container>
  );
};
